
import script from '/home/circleci/app/packages/layout-localization-event-component/src/entries/index';
import { handleCSRError } from '@fiverr-private/layout_lib/CSRTemplate';

try {
    const props = window.initialData['LocalizationEvent'];
    script(props);
}
catch(error) {
    handleCSRError(error, "LocalizationEvent", "LocalizationEvent")
}
